import cn from 'clsx'
import s from './Performance.module.scss'
import { FC, useState } from 'react'
import {
  Text,
  CustomImage,
  Container,
  Button,
  Fade,
  useUI,
} from '@components/ui'
import { isMobileWidth } from '@utils/helpers'
import { useWindowSize } from '@hooks/useWindowSize'
import PerformanceSections from '@components/homepage/PerformanceBlock/PerformanceSections/PerformanceSections'
import { HomepagePerformanceSectionsInner } from '@generated/model/homepagePerformanceSectionsInner'
import { HomepagePerformance } from '@generated/model/homepagePerformance'
import { VideoInner } from '@components/homepage/PerformanceBlock/PerformanceBlock'

type PerformanceProps = {
  data: HomepagePerformance
}

const Performance: FC<PerformanceProps> = ({ data }) => {
  const windowSize = useWindowSize()
  const isMobile = isMobileWidth(windowSize.width)
  const [showExtended, setShowExtended] = useState(0)
  const { pageTheme } = useUI()

  return (
    <>
      {data.sections && (
        <section
          className={cn(s.root, { [s.lightTheme]: pageTheme == 'LIGHT' })}
        >
          <PerformanceSections
            id={`performance-sections`}
            className={s.loreTwoPerformanceSections}
          >
            {data.sections.map((section, i) => (
              <div className={s.slide} key={`performance-section_${i}`}>
                <div className={s.slideContent}>
                  <div className={s.detailContainer}>
                    {showExtended != i + 1 && (
                      <Text
                        className={s.detail}
                        variant="sectionHeadingV2"
                        html={section.detail}
                      />
                    )}
                    {section.detail_extended && showExtended == i + 1 ? (
                      <Text html={section.detail_extended} />
                    ) : (
                      ''
                    )}
                    {section.detail_extended && showExtended != i + 1 && (
                      <Button
                        onClick={() => setShowExtended(i + 1)}
                        variant="ghost"
                        className={s.readMore}
                      >
                        Read More
                      </Button>
                    )}
                    {section.detail_extended && showExtended == i + 1 && (
                      <Button
                        onClick={() => setShowExtended(0)}
                        variant="ghost"
                        className={s.readMore}
                      >
                        Read Less
                      </Button>
                    )}
                  </div>
                  {section.citation && (
                    <Text
                      className={s.citation}
                      html={section.citation}
                      variant="text-3"
                    />
                  )}
                  {section.citation_detail && (
                    <Text
                      className={s.citationDetail}
                      html={section.citation_detail}
                      variant="smallText"
                    />
                  )}
                </div>
                <div className={s.backgroundImage}>
                  <div className={s.backgroundImageInner}>
                    {section.video ? (
                      <VideoInner section={section} />
                    ) : (
                      <CustomImage
                        data={section}
                        layout="fill"
                        objectFit="cover"
                        className={s.backgroundImagePicture}
                      />
                    )}
                    <Fade className={s.fade} />
                  </div>
                </div>
              </div>
            ))}
          </PerformanceSections>
        </section>
      )}
    </>
  )
}

export default Performance
