import cn from 'clsx'
import s from './Hero.module.scss'
import { FC } from 'react'
import { Text, CustomImage, Container, Button } from '@components/ui'
import { isMobileWidth } from '@utils/helpers'
import { useWindowSize } from '@hooks/useWindowSize'
import Image from 'next/image'
import { LoreTwoPage } from '@generated/model/loreTwoPage'
import Link from 'next/link'

type HeroProps = {
  page: LoreTwoPage
}

const Hero: FC<HeroProps> = ({ page }) => {
  const windowSize = useWindowSize()
  const isMobile = isMobileWidth(windowSize.width)

  if (!page.hero || !page.table) return null

  return (
    <>
      <section className={s.root}>
        <div className={s.backgroundContainer}>
          {/* <Image
            className={s.background}
            src="/background-gradient.svg"
            layout="responsive"
            width={390}
            height={166}
          /> */}
        </div>
        <Container>
          <div className={s.inner}>
            <Text
              html={page.hero.title}
              className={s.title}
              variant="pageHeading"
            />
            <Link href={`/product/loretwo`}>
              <a className={s.button}>
                <Button Component="span" className={s.buttonInner}>
                  Order Now
                </Button>
              </a>
            </Link>

            <div className={s.image}>
              <CustomImage data={page.hero} />
            </div>
            <Text
              html={page.hero.detail}
              className={cn(s.detail)}
              variant="sectionHeadingV2"
            />
          </div>

          <div className={s.grid}>
            <div className={s.col}>
              <div className={s.titleContainer}>
                <div className={s.icon}>
                  <Image
                    src={page.table.column_1_icon as string}
                    alt={s.title}
                    width={50}
                    height={50}
                    layout="responsive"
                  />
                </div>
                <Text html={page.table.column_1_title} />
              </div>
              <Text html={page.table.column_1_detail} className={s.detail} />
            </div>
            <div className={s.col}>
              <div className={s.titleContainer}>
                <div className={s.icon}>
                  <Image
                    src={page.table.column_2_icon as string}
                    alt={s.title}
                    width={50}
                    height={50}
                    layout="responsive"
                  />
                </div>
                <Text html={page.table.column_2_title} />
              </div>
              <Text html={page.table.column_2_detail} className={s.detail} />
            </div>
            <div className={s.col}>
              <div className={s.titleContainer}>
                <div className={s.icon}>
                  <Image
                    src={page.table.column_3_icon as string}
                    alt={s.title}
                    width={50}
                    height={50}
                    layout="responsive"
                  />
                </div>
                <Text html={page.table.column_3_title} />
              </div>
              <Text html={page.table.column_3_detail} className={s.detail} />
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default Hero
