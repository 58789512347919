import cn from 'clsx'
import s from './ProductCard.module.scss'
import { FC } from 'react'
import { Text, CustomImage, Container, Button } from '@components/ui'

import { Product } from '@commerce/types/product'
import { LoreTwoPageVariantsInner } from '@generated/model/loreTwoPageVariantsInner'
import Link from 'next/link'
import Image from 'next/image'
import { useWindowSize } from 'usehooks-ts'
import { isMobileWidth } from '@utils/helpers'
import { useRouter } from 'next/router'

type ProductCardProps = {
  product: Product | undefined
  variantData: LoreTwoPageVariantsInner
  className?: string
}

const ProductCard: FC<ProductCardProps> = ({
  product,
  variantData,
  className = '',
}) => {
  const windowSize = useWindowSize()
  const isMobile = isMobileWidth(windowSize.width)

  const router = useRouter()

  const productVariant = product?.variants.find(
    (variant) => variant.id === parseInt(variantData.variant_id)
  )

  const variantPath = productVariant?.options
    .map((opt, ind) => {
      return `${opt.values[0].label.toLowerCase()}/`
    })
    .join('')

  const preview = router.asPath.includes('preview') ? '?preview=true' : ''

  return (
    <>
      <div className={cn(s.root, className)}>
        <div className={s.image}>
          {productVariant?.defaultImage && (
            <Image
              className={s.img}
              src={productVariant.defaultImage.urlOriginal}
              alt={productVariant.defaultImage.altText || 'Product Image'}
              width={isMobile ? windowSize.width : 896}
              height={isMobile ? windowSize.width : 896}
              layout="responsive"
              objectFit="contain"
            />
          )}
        </div>
        <div className={s.content}>
          <div className={s.titleContainer}>
            <Text className={s.title} variant="sectionHeadingV2">
              {variantData.title}
            </Text>
            <div className={s.colors}>
              <div className={s.white}></div>
              <div className={s.black}></div>
            </div>
          </div>
          <Text className={s.detail} html={variantData.detail} />
          <div className={s.orderContainer}>
            <Link href={`/product/loretwo/${variantPath}${preview}`}>
              <a className={s.button}>
                <Button Component="span">Order</Button>
              </a>
            </Link>
            <Text className={s.price}>
              ${productVariant?.prices.price.value}
            </Text>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductCard
