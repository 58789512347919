import cn from 'clsx'
import s from './Table.module.scss'
import { FC } from 'react'
import { Text, CustomImage, Container, Button } from '@components/ui'

import { Product } from '@commerce/types/product'
import ProductCard from './ProductCard/ProductCard'
import { LoreTwoPage } from '@generated/model/loreTwoPage'

type TableProps = {
  page: LoreTwoPage
  product: Product | undefined
}

const Table: FC<TableProps> = ({ page, product }) => {
  if (page.table == null || page.variants == null) return null

  return (
    <>
      <Container>
        <section className={s.root}>
          <div className={cn(s.grid)}>
            <div className={s.col}>
              <Text className={s.titleLarge} variant="pageHeading">
                {page.table.table_title}
              </Text>
            </div>
            <div className={s.col}>
              <div className={s.row2}>
                <Text className={s.detail} html={page.table.table_detail} />
              </div>
            </div>
            <div className={s.col}>
              <div className={s.total}>
                <Text className={s.titleSmall} variant="smallText">
                  {page.table.table_total_title}
                </Text>
                <Text className={s.detailLarge} variant="sectionHeadingV2">
                  {page.table.table_total}
                </Text>
              </div>
              <div className={s.leadtime}>
                <Text className={s.titleSmall} variant="smallText">
                  {page.table.table_lead_time_title}
                </Text>
                <Text className={s.detailLarge} variant="sectionHeadingV2">
                  {page.table.table_lead_time}
                </Text>
              </div>
            </div>

            <div className={s.hr}></div>
            <div className={s.col}>
              <ProductCard
                className={cn(s.card, s.card1)}
                variantData={page.variants[0]}
                product={product}
              />
            </div>
            <div className={s.col}>
              <ProductCard
                className={cn(s.card, s.card2)}
                variantData={page.variants[1]}
                product={product}
              />
            </div>
            <div className={s.col}>
              <ProductCard
                className={cn(s.card, s.card3)}
                variantData={page.variants[2]}
                product={product}
              />
            </div>
          </div>
        </section>
      </Container>
    </>
  )
}

export default Table
